import React, {useState} from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Axios from 'axios'
import {selectUser } from '../auth/users/userSlice'
import { useSelector } from 'react-redux'

import { Link } from 'react-router-dom';
import './InvoiceLast.css'
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Divider, Typography } from '@material-ui/core';
import { Button } from '@material-ui/core';
import PrintIcon from '@mui/icons-material/Print';
import SaveIcon from '@material-ui/icons/Save';
import dbdata from "../db/dbdata.json"
import dbMakes from "../db/dbMakes.json"
import dbModels from "../db/dbModels.json"
import Getidinvoice from '../components/Getidinvoice';
import {businessname, bstreet, bcity, bemail, bphone} from '../models/European'
import logo from '../assets/image/logo.png'
 
const  Estimate = (props) => { 


  const user = useSelector(selectUser)

  const [idinvoice, setidinvoice] = useState('')
  const [name,      setname] = useState('')
  const [phone,     setphone] = useState('')
  const [address,   setaddress] = useState('')
  const [location,   setlocation] = useState('')
  const [date,      setdate] = useState('')
  const [brand,     setbrand] = useState('')
  const [make,      setmake] = useState('')
  const [model,     setmodel] = useState('')
  const [year,      setyear] = useState('')
  const [license,   setlicense] = useState('')
  const [bin,       setbin] = useState('')
  const [mileage,   setmileage] = useState('')
  const [adate,     setadate] = useState('')
  const [ddate,     setddate] = useState('')
     
      const [pri1, setpri1] = useState(0)
      const [lab1, setlab1] = useState(0)
      const [qua1, setqua1] = useState(0)
      const [des1, setdes1] = useState('')
      const [war1, setwar1] = useState('')
      const [pri2, setpri2] = useState(0)
      const [lab2, setlab2] = useState(0)
      const [qua2, setqua2] = useState(0)
      const [des2, setdes2] = useState('')
      const [war2, setwar2] = useState('')
      const [pri3, setpri3] = useState(0)
      const [lab3, setlab3] = useState(0)
      const [qua3, setqua3] = useState(0)
      const [des3, setdes3] = useState('')
      const [war3, setwar3] = useState('')
      const [pri4, setpri4] = useState(0)
      const [lab4, setlab4] = useState(0)
      const [qua4, setqua4] = useState(0)
      const [des4, setdes4] = useState('')
      const [war4, setwar4] = useState('')
      const [pri5, setpri5] = useState(0)
      const [lab5, setlab5] = useState(0)
      const [qua5, setqua5] = useState(0)
      const [des5, setdes5] = useState('')
      const [war5, setwar5] = useState('')
      const [pri6, setpri6] = useState(0)
      const [lab6, setlab6] = useState(0)
      const [qua6, setqua6] = useState(0)
      const [des6, setdes6] = useState('')
      const [war6, setwar6] = useState('')
      
     function myChangeHandleridinvoice(event) { setidinvoice(event.target.value)}
     function myChangeHandlername(event) { setname(event.target.value)}
     function myChangeHandlerphone(event) { setphone(event.target.value)}     
     function myChangeHandleraddress(event) { setaddress(event.target.value)}     
     function myChangeHandlerlocation(event) { setlocation(event.target.value)}     
     function myChangeHandlerdate(event) { setdate(event.target.value)}
     function myChangeHandlerbrand(event) { setbrand(event.target.value)}
     function myChangeHandlermake(event) { setmake(event.target.value)}
     function myChangeHandlermodel(event) { setmodel(event.target.value)}
     function myChangeHandleryear(event) { setyear(event.target.value)}
     function myChangeHandlerlicense(event) { setlicense(event.target.value)}
     function myChangeHandlerbin(event) { setbin(event.target.value)}
     
     function myChangeHandlermileage(event) { setmileage(event.target.value)}
     function myChangeHandleradate(event) { setadate(event.target.value)}
     function myChangeHandlerddate(event) { setddate(event.target.value)}

     function myChangeHandlerpri1(event) { setpri1(event.target.value)}
     function myChangeHandlerlab1(event) { setlab1(event.target.value)}
     function myChangeHandlerqua1(event) { setqua1(event.target.value)}     
     function myChangeHandlerdes1(event) { setdes1(event.target.value)}     
     function myChangeHandlerwar1(event) { setwar1(event.target.value)}     
     function myChangeHandlerpri2(event) { setpri2(event.target.value)}
     function myChangeHandlerlab2(event) { setlab2(event.target.value)}
     function myChangeHandlerqua2(event) { setqua2(event.target.value)}
     function myChangeHandlerdes2(event) { setdes2(event.target.value)}     
     function myChangeHandlerwar2(event) { setwar2(event.target.value)}
     function myChangeHandlerpri3(event) { setpri3(event.target.value)}
     function myChangeHandlerlab3(event) { setlab3(event.target.value)}
     function myChangeHandlerqua3(event) { setqua3(event.target.value)}
     function myChangeHandlerdes3(event) { setdes3(event.target.value)}     
     function myChangeHandlerwar3(event) { setwar3(event.target.value)}
     function myChangeHandlerpri4(event) { setpri4(event.target.value)}
     function myChangeHandlerlab4(event) { setlab4(event.target.value)}
     function myChangeHandlerqua4(event) { setqua4(event.target.value)}
     function myChangeHandlerdes4(event) { setdes4(event.target.value)}     
     function myChangeHandlerwar4(event) { setwar4(event.target.value)}
     function myChangeHandlerpri5(event) { setpri5(event.target.value)}
     function myChangeHandlerlab5(event) { setlab5(event.target.value)}
     function myChangeHandlerqua5(event) { setqua5(event.target.value)}
     function myChangeHandlerdes5(event) { setdes5(event.target.value)}     
     function myChangeHandlerwar5(event) { setwar5(event.target.value)}
     function myChangeHandlerpri6(event) { setpri6(event.target.value)}
     function myChangeHandlerlab6(event) { setlab6(event.target.value)}
     function myChangeHandlerqua6(event) { setqua6(event.target.value)}
     function myChangeHandlerdes6(event) { setdes6(event.target.value)}     
     function myChangeHandlerwar6(event) { setwar6(event.target.value)}
     function print(){
         window.print()
       }

       const authentication = () => {          
 
       const data = {
        "Idinvoice": idinvoice,
        "Name":      name,
        "Phone":     phone,
        "Address":   address,
        "Location":  location,
        "Date":      date,
        "Brand":     brand,
        "Make":      make,
        "Model":     model,
        "Year":      year,
        "License":   license,
        "Bin":       bin,
        "Mileage":   mileage,
        "Adate":     adate,
        "Ddate":     ddate,
          "Lines_details": [         
              {
                  "Des": des1,
                  "Pri": pri1,
                  "Qua": qua1,
                  "Lab": lab1,
                  "War": war1
              },
              { 
                  "Des": des2,
                  "Pri": pri2,
                  "Qua": qua2,
                  "Lab": lab2,
                  "War": war2
              },
              {
                "Des": des3,
                "Pri": pri3,
                "Qua": qua3,
                "Lab": lab3,
                "War": war3
              },
              {
                "Des": des4,
                "Pri": pri4,
                "Qua": qua4,
                "Lab": lab4,
                "War": war4
              },
              {
                "Des": des5,
                "Pri": pri5,
                "Qua": qua5,
                "Lab": lab5,
                "War": war5
              },
              {
                "Des": des6,
                "Pri": pri6,
                "Qua": qua6,
                "Lab": lab6,
                "War": war6
              }
          ]
      }  

      Axios.post(`${process.env.REACT_APP_BASE_URL}${user.uid}/${process.env.REACT_APP_CREATE_ESTIMATE}/${user.uid}/data.json`, data)
        .then(r => {
          localStorage.setItem('token', r.data.token)
          window.location = "/"
        }).then(() => alert('The Estimate has been succesfully saved'))
        .catch(e => {
          alert('error al iniciar sesion')
        } )
      }

    return (
        <>
        <Container style={{marginTop:'40px'}}>   
            <form >
          <Row className='mb-5'>
              <Col sm={3} className='text-center'> 
              <div >
                  <Link to='/account'>
                   <img               
                  style={{width: '300px', height:150}}
                  src={logo}
                 alt='..'  /> 
                  </Link>
              </div>
               </Col>
              <Col sm={6} className="text-center" > 
              <h2 className="text-center">{businessname}</h2>
                  <p className="text-center">{bstreet}<br/>
                  {bcity}<br/>{bphone}</p>
              </Col>
              <Col sm={3} className="text-center"> 
              <h3 >Repair Invoice</h3>
              </Col>
          </Row>          
          <Divider variant="middle" />
          <Divider variant="middle" />
          <Row className='mb-3'>        
              <Col md={4}> 
                <Row>                
                  <Col >
                  <TextField style={{ fontSize:25}} fullWidth   id="Name" name='Name' type='text' label={
                        <Typography  variant="h6" > Customer Name: </Typography>
                      } variant="standard" onChange={myChangeHandlername} />
                  </Col>
                </Row>
                <Row>                
                  <Col>
                  <TextField style={{ marginTop:10, fontSize:25}} fullWidth id="Phone" name="Phone" type='number' label={
                        <Typography  variant="h6" > Contact Number: </Typography>
                      } variant="standard" onChange={myChangeHandlerphone} />
                  </Col>
                </Row>
                {/* <h4>Bill To:</h4>                
                <TextField  id="Name" name='Name' type='text' label="Full Name" variant="standard" />
                <TextField id="Phone" name="Phone" type='number' label="Phone Number" variant="standard" /> */}
              </Col>
              <Col>
              <Row>                
                  <Col >
                  <TextField style={{ fontSize:25}} fullWidth  id="Address" name='Address' type='text' label={
                        <Typography  variant="h6"> Street: </Typography>
                      } variant="standard" onChange={myChangeHandleraddress} />
                  </Col>
              </Row>
              <Row>                
                  <Col>
                  <TextField style={{ marginTop:10, fontSize:25}} fullWidth id="Location" name="Location" type='text' label={
                        <Typography  variant="h6" > City / State: </Typography>
                      } variant="standard" onChange={myChangeHandlerlocation} />
                  </Col>
              </Row>
              </Col>
              <Col>
              <Row>                
                  <Col>  
                      <Row>
                          <Col md={7} style={{marginTop:25 }}>
                              <h5 >Estimate No:</h5>
                          </Col>
                          <Col md={5} style={{alignItems:'left', marginTop:25}}>
                              {/* <Getidestimate /> */}
                              <TextField  id="Idinvoice" name="Idinvoice" type='number'  variant="standard" onChange={myChangeHandleridinvoice} />
                          </Col>
                      </Row>              
                  </Col>
              </Row>
              <Row>                
                  <Col >
                      <Row>
                          <Col md={4}>
                              <h5 style={{marginTop:20 }}>Date:</h5>
                          </Col>
                          <Col  md={8}>
                              <TextField style={{marginTop:20, fontSize:25}}   id="Date" name='Date' type='date'  variant="standard" onChange={myChangeHandlerdate} />
                          </Col>
                      </Row>                
                  </Col>
              </Row>
           
              </Col>
              {/* <TextField  style={{width:'60%', marginTop:10, fontSize:25}}  id="Date" name="Date" type='date'  variant="standard" /> */}
              {/* <Getidinvoice />
              <TextField  id="Idinvoice" name="Idinvoice" type='number'  variant="standard" /> */}         
           
         </Row>
        
        <h4 className='mt-5'>Car Information</h4>
        {/* <Divider variant="middle" /> */}
        <Row className='mb-3'>            
            <Col md={4}>
                <Row>
                    <Col md={5}>
                    <br/>
                    <h5>Car Name:</h5>                            
                    </Col>
                    <Col md={7}>
                    <Autocomplete
                      id="idBrand"
                      options={dbMakes.makes}
                      getOptionLabel={(option) => option.title}
                      style={{ width: 160 }}
                      renderInput={(params) => <TextField {...params} id="Brand" name="Brand" type='text' label=" "  variant="standard"  onSelect={myChangeHandlerbrand} />}
                    /> 
                    </Col>
                </Row> 
                <Row>
                    <Col md={5}>
                    <br/>
                    <h5>Year:</h5>                            
                    </Col>
                    <Col md={7}>
                    <Autocomplete
                      id="idYear"
                      options={dbdata.year}
                      getOptionLabel={(option) => option.title}
                      style={{ width: 160 }}
                      renderInput={(params) => <TextField {...params} id="Year" name="Year" type='text' label=" " variant="standard" onSelect={myChangeHandleryear} />}
                    /> 
                    </Col> 
                </Row>
                <Row>
                    <Col md={5}>
                    <br/>
                    <h5 style={{ fontSize:18 }}>Mileage:</h5>                            
                    </Col>
                    <Col md={7}>
                    <TextField style={{ width: 160 }} id="Mileage" name="Mileage" type='number' label=" " variant="standard" onChange={myChangeHandlermileage} />
                    </Col> 
                </Row>                                              
            </Col>  
            <Col md={4}>
            <Row>
                    <Col md={4}>
                    <br/>
                    <h5>Make:</h5>                            
                    </Col>
                    <Col md={8}>
                    <Autocomplete
                      id="idMake"
                      options={ dbMakes.makes}
                      getOptionLabel={(option) => option.title}
                      style={{ width: 200 }}
                      renderInput={(params) => <TextField {...params} id="Make" name="Make" type='text' label=" " variant="standard" onSelect={myChangeHandlermake} />}
                    /> 
                    </Col>
                </Row>                 
                <Row>
                    <Col md={4}>
                    <br/>
                    <h5>LPN:</h5>                            
                    </Col>
                    <Col md={8}>
                    <TextField  style={{ width: 200 }} id="License" name="License" type='text' label="License Plate Number" variant="standard" onChange={myChangeHandlerlicense} />
                  
                    </Col>
                </Row> 
                <Row>
                    <Col md={4}>
                    <br/>
                    <h5 style={{ fontSize:18 }}>Date in:</h5>                            
                    </Col>
                    <Col md={8}>
                    <TextField style={{ width:220, marginTop:17 }} size='small'  id="Adate" name="Adate" type='datetime-local'  variant="standard" onChange={myChangeHandleradate} />
                    </Col> 
                </Row>                                 
            </Col> 
            <Col md={4}>
            <Row>
                    <Col md={4}>
                    <br/>
                    <h5>Model:</h5>                            
                    </Col>
                    <Col md={8}>
                    <Autocomplete
                      id="idModel"
                      options={ dbModels.models}
                      getOptionLabel={(option) => option.title}
                      style={{ width: 180 }}
                      renderInput={(params) => <TextField {...params} id="Model" name="Model" type='text' label=" " variant="standard"  onSelect={myChangeHandlermodel}/>}
                    /> 
                    </Col>
                </Row> 
                <Row>
                    <Col md={4}>
                     <br/>
                    <h5>VIN:</h5>                            
                    </Col>
                    <Col md={8}>
                    <TextField  style={{ width: 180 }} id="Bin" name="Bin" type='text' label=" " variant="standard"  onChange={myChangeHandlerbin} />                    
                    </Col>
                </Row>  
                <Row>
                    <Col md={4}>
                    <br/>
                    <h5 style={{ fontSize:17 }}>Date out:</h5>                            
                    </Col>
                    <Col md={7}>
                    <TextField  style={{ width:220, marginTop:17 }} size='small'  id="Ddate" name="Ddate" type='datetime-local'  variant="standard" onChange={myChangeHandlerddate} />
                    </Col> 
                </Row>                                 
            </Col>           
        </Row>
        <Row className='mb-5' >
        <table className="table  table-sm ">
            <thead>
              <tr className='text-center'>
                <th style={{width:'20px', borderBottomColor:'black'}}>#</th>
                <th style={{width:'330px', borderBottomColor:'black'}}>Description</th>
                <th style={{width:'90px', borderBottomColor:'black'}}>Price $</th>
                <th style={{width:'90px', borderBottomColor:'black'}}>Quantity</th>
                <th style={{width:'90px', borderBottomColor:'black'}}>Labor $</th>
                <th style={{width:'110px', borderBottomColor:'black'}}>Warranty</th>
                <th style={{width:'100px', borderBottomColor:'black'}}>Total $</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{verticalAlign: 'middle' }} className='text-center'>1</td>
                <td><TextField multiline  fullWidth={true} inputProps={{min: 0, style: { textAlign: 'left' }}} id="Des1" name="Des" type='text' label="" variant="standard" onChange={myChangeHandlerdes1} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center'}}} id="Pri1" name="Pri" type='number' label="" variant="standard"  onChange={myChangeHandlerpri1} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Qua1" name="Qua" type='number' label="" variant="standard" onChange={myChangeHandlerqua1} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Lab1" name="Lab" type='number' label="" variant="standard" onChange={myChangeHandlerlab1} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="War1" name="War" type='text' label="" variant="standard" onChange={myChangeHandlerwar1} /></td>
                <td style={{verticalAlign: 'middle' }} className='text-center' >{((pri1 ?  parseInt(pri1) * parseInt(qua1) : 0) + (lab1 ? parseInt(lab1) : 0 )).toFixed(2)}</td>
              </tr>
              <tr>
                <td style={{verticalAlign: 'middle' }} className='text-center'>2</td>
                <td><TextField multiline fullWidth={true} inputProps={{min: 0, style: { textAlign: 'left' }}}  id="Des2" name="Des" type='text' label="" variant="standard"  onChange={myChangeHandlerdes2}/></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Pri2" name="Pri" type='number' label="" variant="standard" onChange={myChangeHandlerpri2} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Qua2" name="Qua" type='number' label="" variant="standard" onChange={myChangeHandlerqua2} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Lab2" name="Lab" type='number' label="" variant="standard" onChange={myChangeHandlerlab2} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField  inputProps={{min: 0, style: { textAlign: 'center' }}} id="War2" name="War" type='text' label="" variant="standard" onChange={myChangeHandlerwar2} /></td>
                <td style={{verticalAlign: 'middle' }} className='text-center' >{((pri2 ?  parseInt(pri2) * parseInt(qua2) : 0) + (lab2 ? parseInt(lab2) : 0 )).toFixed(2)}</td>
              </tr>
              <tr>
                <td style={{verticalAlign: 'middle' }} className='text-center' >3</td>
                <td ><TextField multiline fullWidth={true} inputProps={{min: 0, style: { textAlign: 'left' }}}  id="Des3" name="Des" type='text' label="" variant="standard" onChange={myChangeHandlerdes3} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Pri3" name="Pri" type='number' label="" variant="standard" onChange={myChangeHandlerpri3} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Qua3" name="Qua" type='number' label="" variant="standard" onChange={myChangeHandlerqua3} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Lab3" name="Lab" type='number' label="" variant="standard"  onChange={myChangeHandlerlab3}/></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="War3" name="War" type='text' label="" variant="standard" onChange={myChangeHandlerwar3} /></td>
                <td style={{verticalAlign: 'middle' }} className='text-center'>{((pri3 ?  parseInt(pri3) * parseInt(qua3) : 0) + (lab3 ? parseInt(lab3) : 0 )).toFixed(2)}</td>

              </tr> 
             
                <tr >
                <td style={{verticalAlign: 'middle' }} className='text-center'>4</td>
                <td><TextField multiline fullWidth={true} inputProps={{min: 0, style: { textAlign: 'left' }}}  id="Des4" name="Des" type='text' label="" variant="standard"  onChange={myChangeHandlerdes4} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Pri4" name="Pri" type='number' label="" variant="standard"  onChange={myChangeHandlerpri4} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Qua4" name="Qua" type='number' label="" variant="standard" onChange={myChangeHandlerqua4} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Lab4" name="Lab" type='number' label="" variant="standard"   onChange={myChangeHandlerlab4}/></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="War4" name="War" type='text' label="" variant="standard" onChange={myChangeHandlerwar4} /></td>
                <td style={{verticalAlign: 'middle' }} className='text-center'>{((pri4 ?  parseInt(pri4) * parseInt(qua4) : 0) + (lab4 ? parseInt(lab4) : 0 )).toFixed(2)}</td>
              </tr> 
              
              <tr >
                <td style={{verticalAlign: 'middle' }} className='text-center'>5</td>
                <td><TextField multiline fullWidth={true} inputProps={{min: 0, style: { textAlign: 'left' }}}  id="Des5" name="Des" type='text' label="" variant="standard" onChange={myChangeHandlerdes5} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Pri5" name="Pri" type='number' label="" variant="standard"  onChange={myChangeHandlerpri5} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Qua5" name="Qua" type='number' label="" variant="standard" onChange={myChangeHandlerqua5} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Lab5" name="Lab" type='number' label="" variant="standard"   onChange={myChangeHandlerlab5}/></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="War5" name="War" type='text' label="" variant="standard" onChange={myChangeHandlerwar5} /></td>
                <td style={{verticalAlign: 'middle' }} className='text-center'>{((pri5 ?  parseInt(pri5) * parseInt(qua5) : 0) + (lab5 ? parseInt(lab5) : 0 )).toFixed(2)}</td>
              </tr> 
             
               <tr >
                <td style={{verticalAlign: 'middle' }} className='text-center'>6</td>
                <td><TextField multiline fullWidth={true} inputProps={{min: 0, style: { textAlign: 'left' }}}  id="Des6" name="Des" type='text' label="" variant="standard" onChange={myChangeHandlerdes6} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Pri6" name="Pri" type='number' label="" variant="standard"  onChange={myChangeHandlerpri6} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Qua6" name="Qua" type='number' label="" variant="standard" onChange={myChangeHandlerqua6} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Lab6" name="Lab" type='number' label="" variant="standard"   onChange={myChangeHandlerlab6}/></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="War6" name="War" type='text' label="" variant="standard" onChange={myChangeHandlerwar6} /></td>
                <td style={{verticalAlign: 'middle' }} className='text-center'>{((pri6 ?  parseInt(pri6) * parseInt(qua6) : 0) + (lab6 ? parseInt(lab6) : 0 )).toFixed(2)}</td>
              </tr>                     
              <tr>
                <td className='text-center' style={{borderColor:'white'}} ></td>
                <td style={{borderColor:'white'}}></td>
                <td style={{borderColor:'white'}}></td>
                <td style={{borderColor:'white'}}></td>
                <td style={{borderBottomColor:'white'}}></td>
                <td style={{borderColor:'black'}} className='text-left' ><h6><strong>Total $</strong></h6></td>
                <td style={{borderColor:'black'}} className='text-center' >
                    <h6>
                    <strong>
                    {
                        (((pri1 ? parseInt(pri1) * parseInt(qua1) : 0) + (lab1 ? parseInt(lab1) : 0 )) + 
                        ((pri2 ?  parseInt(pri2) * parseInt(qua2) : 0) + (lab2 ? parseInt(lab2) : 0 )) +
                        ((pri3 ?  parseInt(pri3) * parseInt(qua3) : 0) + (lab3 ? parseInt(lab3) : 0 )) + 
                        ((pri4 ?  parseInt(pri4) * parseInt(qua4) : 0) + (lab4 ? parseInt(lab4) : 0 )) +
                        ((pri5 ?  parseInt(pri5) * parseInt(qua5) : 0) + (lab5 ? parseInt(lab5) : 0 )) + 
                        ((pri6 ?  parseInt(pri6) * parseInt(qua6) : 0) + (lab6 ? parseInt(lab6) : 0 ))).toFixed(2)

                    }
                    </strong>
                    </h6>
                </td>
              </tr>                 
              
            </tbody>
            </table>
        </Row>       
        <Row className='mb-2'>
            <Col md={6} className='text-center'>
            </Col>
            <Col md={6} className='text-center mt-5'>
                <h5>{businessname}</h5>                
            </Col>
        </Row>    
     
        <Row style={{marginTop:200}}>
        <Col md={4} className='text-center' >
                <span><strong>{bemail}</strong></span>
            </Col>         
            <Col md={4} className='text-center'>
                <h5 ><strong>Thanks for choosing us.</strong></h5>
            </Col>
            <Col md={4} >
              <Row>
                <Col>
                   <Button                                
                        type="button"
                         variant="outlined"
                         color="primary"
                         size="small"
                         className="primary"
                         startIcon={<SaveIcon />}   
                         onClick={authentication}               
                        >
                         Save
                    </Button>
                </Col>
                <Col>
                    <Button    
                           onClick={print}  
                            variant="outlined"
                            color="secondary"
                            size="small"
                            className="primary"
                            startIcon={<PrintIcon />}                  
                           >
                            Print
                       </Button>
                </Col>
              </Row>               
            </Col>
        </Row>
        </form>
      </Container>
      </>
)}

export default Estimate
