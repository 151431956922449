class Invoice {
    constructor(id, Idinvoice, Name, Phone, Address, City, Zip, Keyss, Driver, Date1, Year, Make, Model, Vin, Color, License, State,  Towedf, Towedt, Hoop, Mileage, Dollies, Winch, Lock, Gas, Clean, Dispatch, Gate, Secondtow, Secondtowmilles, Store, Jumpstart, Other, Total, 
      Front, Rear, Right, Left, Date2, Payment, Hooptext, Mileagetext, Dolliestext, Winchtext, Locktext, Gastext, Cleantext, Dispatchtext, Gatetext, Secondtowtext, Secondtowmillestext, Storetext, Jumpstarttext, Othertext  ) {
      this.id = id;
      this.Idinvoice = Idinvoice;
      this.Name = Name;
      this.Phone = Phone;
      this.Address = Address;
      this.City = City;
      this.Zip = Zip;
      this.Keyss = Keyss;
      this.Driver = Driver;
      this.Date1 = Date1;
      this.Year = Year;
      this.Make = Make;
      this.Model = Model;
      this.Vin = Vin;
      this.Color = Color;
      this.License = License;
      this.State = State;
      this.Towedf = Towedf;
      this.Towedt = Towedt;
      this.Hoop = Hoop;
      this.Mileage = Mileage;
      this.Dollies = Dollies;
      this.Winch = Winch;
      this.Lock = Lock;
      this.Gas = Gas;
      this.Clean = Clean;
      this.Dispatch = Dispatch;
      this.Gate = Gate;
      this.Secondtow = Secondtow;
      this.Secondtowmilles = Secondtowmilles;
      this.Store = Store;
      this.Jumpstart = Jumpstart;
      this.Other = Other;
      this.Total = Total;
      this.Front = Front;
      this.Rear = Rear;
      this.Right = Right;
      this.Left = Left;
      this.Date2 = Date2;
      this.Payment = Payment;
      this.Hooptext = Hooptext;
      this.Mileagetext = Mileagetext;
      this.Dolliestext = Dolliestext;
      this.Winchtext = Winchtext;
      this.Locktext = Locktext;
      this.Gastext = Gastext;
      this.Cleantext = Cleantext;
      this.Dispatchtext = Dispatchtext;
      this.Gatetext = Gatetext;
      this.Secondtowtext = Secondtowtext;
      this.Secondtowmillestext = Secondtowmillestext;
      this.Storetext = Storetext;
      this.Jumpstarttext = Jumpstarttext;
      this.Othertext = Othertext;
    }
  }
  
  export default Invoice;
  